.inbox-tab-wrapper {
    position: relative;
    display: flex;
}

.ui.floating.label.inbox-unread-count-label {
    top: 2px; 
    padding: 4px;
    border-radius: 10em;
    min-width: 20px;
    text-align: center;
}
