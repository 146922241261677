.scans-scan-timestamp {
    font-weight: normal;
    font-size: 0.8em;
    color: #aaa;
}

.scans-scan-row:hover {
    background: rgba(0,0,0,0.01);
}

.scans-scan-row:not(:hover) .scans-scan-action-buttons {
    display: none;
}
