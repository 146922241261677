.thin-dropdown-control {
    padding-top: 2px;
    padding-bottom: 2px;
}

.thin-dropdown-control .Dropdown-arrow {
    top: 10px;
}

.checkbox-item-wrapper {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 1.8em;
    padding: 4px;
    border-radius: 3px;
}

.checkbox-item-wrapper:hover {
    background: rgba(0,0,0,0.05);
}

i.icon.checkbox-item-button {
    display: none;
    margin-right: 4px;
    margin-bottom: 5px;
    margin-left: 10px;
}

.checkbox-item-wrapper:hover i.icon.checkbox-item-button {
    display: inline;
}

.taskcard-description blockquote {
    margin-left: 0;
    margin-right: 0;
    background: #fff8db;
    color: #5e4303;
    box-shadow: 0 0 0 1px #b58105 inset,0 0 0 0 transparent;
    padding: 1em 1.5em;
    border-radius: .28571429rem;
}

.attachment-item-wrapper {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 1.8em;
    padding: 4px;
    border-radius: 3px;
}

.attachment-item-wrapper:hover {
    background: rgba(0,0,0,0.05);
}

i.icon.attachment-item-button {
    display: none;
    margin-right: 4px;
    margin-bottom: 5px;
    margin-left: 10px;
}

.attachment-item-wrapper:hover i.icon.attachment-item-button {
    display: inline;
}
