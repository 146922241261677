.pushnotificationpreview .appicon {
    width: 30px;
    height: 30px;
    border-radius: 8px;
}
.pushnotificationpreview .apptitle {
    color: #484848;
    margin-left: 10px;
    font-size: 0.7em;
    line-height: 30px;
    vertical-align: middle;
}
.pushnotificationpreview .notificationtitle {
    font-weight: bold;
    margin-top: 6px;
}
.pushnotificationpreview .notificationbody {
}
.pushnotificationpreview {
    padding: 10px;
    border-radius: 8px;
    margin-left: 20px;
    margin-right: 20px;
    max-width: 500px;
}
.pushnotificationpreview .brandbackground {
    background: #ECF0F1;
}
.pushnotificationpreview .brandborder {
    border: 1px solid #BBC2C4;
}
