.mouseoverdetector:hover .mouseover {
    display: inherit;
}

.mouseoverdetector:hover .nonmouseover {
    display: none;
}

.mouseoverdetector .mouseover {
    display: none;
}

.mouseoverdetector .nonmouseover {
    display: inherit;
}


.ui.feed > .event > .label .icon.dim:not(:hover) {
    color: rgba(0,0,0,.15);
}

.ui.feed > .event {
    margin-bottom: 10px;
    border-bottom: 1px solid #f1efef;
    padding-bottom: 20px;
}