#root {
    height: 100%;
}

.align-top {
    vertical-align: top;
}

.align-middle {
    vertical-align: middle;
}

.align-baseline {
    vertical-align: baseline;
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: 0.25em;
}

.m-4 {
    margin: 1em;
}

.m-8 {
    margin: 2em;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 0.25em;
}

.mb-2 {
    margin-bottom: 0.5em;
}

.mb-4 {
    margin-bottom: 1em;
}

.mb-8 {
    margin-bottom: 2em;
}

.mt-3 {
    margin-top: 0.75rem;
}

.mt-4 {
    margin-top: 1em;
}

.mt-8 {
    margin-top: 2em;
}

.mt-12 {
    margin-top: 3em;
}

.mt-16 {
    margin-top: 4em;
}

.ml-4 {
    margin-left: 1em;
}

.mr-4 {
    margin-right: 1em;
}

.text-sm {
    font-size: .875rem;
}

.p-0 {
    padding: 0;
}

.ui.grid > .row.p-0 {
    padding: 0;
}

.ui.grid > .row.p-2 {
    padding: 0.5em;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-grow {
    flex-grow: 1;
}

.w-24 {
    width: 6rem;
}

.w-40 {
    width: 10rem;
}

.w-64 {
    width: 16rem;
}

.w-88 {
    width: 22rem;
}

.min-w-64 {
    min-width: 16rem;
}

.whitespace-no-wrap {
    white-space: nowrap;
}

.w-full {
    width: 100%;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(50,50,50,0.75);
}

.field.error .Dropdown-control {
    border-color: #9f3a38;
    background-color: #fff6f6;
}

.field.error .Dropdown-placeholder.is-selected {
    color: #9f3a38;
}

.hcenter-vcenter {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dim {
    color: #999;
    font-style: italic;
}

.hidden {
    display: none !important;
}

.ReactModal__Overlay {
    z-index: 100;
}

.border-none {
    border-style: none !important;
}

.Dropdown-control:hover {
    color: #1e70bf;
    cursor: pointer;
}

.react-pdf__Page canvas {
    border: 1px solid #CCC;
}

.font-bold {
    font-weight: bold;
}

.ui.comments .reply.form .DraftEditor-root {
    max-height: 14em;
    overflow-y: auto;
}

.public-DraftEditorPlaceholder-inner {
    height: 0;
    overflow: visible;
    color: #CCC;
}

.ui.raised.fluid.card {
    max-width: calc(100vw - 350px);
    width: 1600px;
    margin: 0;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.ui.tertiary.button {
    box-shadow: none;
    background: transparent;
    text-decoration: none;
    color: hsl(0,0,50%);
    padding-left: 0;
    padding-right: 0;
}

.ui.tertiary.button:hover {
    text-decoration: underline;
    color: #4183c4;
}

.filter-bar {
    display: flex;
    padding: 8px;
    border: 1px solid #CCC;
    border-top: none;
    background: #fafafa;
}

.filter-bar > div {
    margin-right: 1em;
}

.readOnly {
    pointer-events: none;
}

.modal-rail {
    width: 40%; 
    min-width: 300px;
    max-width: 400px;
    margin-left: 15px;
    min-height: 60vh;
    max-height: calc(100vh - 40px);
}

.ui.secondary.pointing.menu .item {
    margin: 0 0 -10px;   
}

.ui.fluid.limitedwidth.container {
    max-width: 1500px !important;
}

.rightbar {
    margin-top: 0;
    padding-left: 20px;
}

.rightbar.dividing {
    border-left: 1px solid #CCC;
}

.ui.floating.label {
    z-index: 90 !important;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}