.date-picker-dropdown {
    border: 1px solid #CCC;
    cursor: pointer;
}

.date-picker-dropdown .caret.down.icon {
    padding-left: 10px;
    color: #999;
}

.date-picker-dropdown span:hover, .date-picker-dropdown .close.icon:hover {
    color: #1e70bf;
}

