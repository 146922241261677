.inbox-message-timestamp {
    font-weight: normal;
    font-size: 0.8em;
    color: #aaa;
}

.inbox-message-row:hover {
    background: rgba(0,0,0,0.01);
}

.inbox-message-row:not(:hover) .inbox-message-action-buttons {
    display: none;
}
